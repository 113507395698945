import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}




const routes = [{
		path: '/',
		name: 'index',
		component: () => import('@/views/index2022/index2022'),
	},
	{
		path: '/online-experience',
		name: 'onlineExperience',
		component: () => import('@/views/product/dooryard/online-experience')
	},
	{
		path: '/offline-experience',
		name: 'offlineExperience',
		component: () => import('@/views/product/dooryard/offline-experience')
	},
	{
		path: '/life-brand',
		name: 'lifeBrand',
		component: () => import('@/views/life/brand')
	},
	{
		path: '/life-project',
		name: 'lifeProject',
		component: () => import('@/views/life/project')
	},
	{
		path: '/life-milestone',
		name: 'lifeMilestone',
		component: () => import('@/views/life/milestone')
	},
	{
		path: '/life-culture',
		name: 'lifeCulture',
		component: () => import('@/views/life/culture')
	},
	{
		path: '/life-contact',
		name: 'lifeContact',
		component: () => import('@/views/life/contact')
	},
	{
		path: '/news-list/:paramsName',
		name: 'newsList',
		component: () => import('@/views/news/list')
	},
	{
		path: '/news-detail/:id',
		name: 'newsDetail',
		component: () => import('@/views/news/detail')
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: () => import('@/views/feedback/feedback'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/product-lifehall',
		name: 'productLifehall',
		component: () => import('@/views/product/lifehall')
	},
	{
		path: '/product-dooryard',
		name: 'productDooryard',
		component: () => import('@/views/product/dooryard/dooryard')
	},
	{
		path: '/product-dooryard/detail/:houseType',
		name: 'productDooryardDetail',
		component: () => import('@/views/product/dooryard/detail')
	},
	{
		path: '/product-dooryard/project/future-city',
		name: 'futureCity',
		component: () => import('@/views/product/dooryard/project/futureCity')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/member/login')
	},
	{
		path: '/privacy-policy',
		name: 'privacyPolicy',
		component: () => import('@/views/member/privacy-policy')
	},
	{
		path: '/member',
		name: 'member',
		component: () => import('@/views/member/member'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/member-info',
		name: 'memberInfo',
		component: () => import('@/views/member/info'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/exchange',
		name: 'exchange',
		component: () => import('@/views/member/exchange/list'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/exchange-detail/:goodsId',
		name: 'exchangeDetail',
		component: () => import('@/views/member/exchange/detail'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/exchange-history',
		name: 'exchangeHistory',
		component: () => import('@/views/member/exchange/history'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/integral-history',
		name: 'integralHistory',
		component: () => import('@/views/member/integral-history'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/coupon',
		name: 'couponList',
		component: () => import('@/views/member/coupon/list'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/appointment',
		name: 'appointment',
		component: () => import('@/views/member/appointment'),
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		}
	},
	{
		path: '/index2022',
		name: 'index2022',
		component: () => import('@/views/index/index')
	},
	{
		path: '/fitness',
		name: 'fitness',
		component: () => import('@/views/lifehall/fitness'),
	},
	{
		path: '/library',
		name: 'library',
		component: () => import('@/views/lifehall/library'),
	},
	{
		path: '/starbucks',
		name: 'starbucks',
		component: () => import('@/views/lifehall/starbucks'),
	},
	{
		path: '/shared-office',
		name: 'sharedOffice',
		component: () => import('@/views/lifehall/shared-office'),
	},
	{
		path: '/house-config/:id',
		name: 'houseConfig',
		component: () => import('@/views/product/house-config')
	},
	{
		path: '/project/:paramsName',
		name: 'project',
		component: () => import('@/views/product/project')
	},
	{
		path: '/house-custom',
		name: 'houseCustom',
		component: () => import('@/views/product/house-custom')
	},
	{
		path: '/reserve-order',
		name: 'reserveOrder',
		component: () => import('@/views/product/reserve-order')
	},
	{
		path: '/weixin-pay/:id/:code',
		name: 'weixinPay',
		component: () => import('@/views/product/weixin-pay')
	},
	{
		path: '/smart0',
		name: 'smart0',
		component: () => import('@/views/product/smart0/smart0'),
		
	},
	{
		path: '/project-list',
		name: 'projectList',
		component: () => import('@/views/product/smart0/project-list')
	},
	{
		path: '/smart0-intention',
		name: 'smart0Intention',
		component: () => import('@/views/product/smart0/submit-intention')
	}

]

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior: function(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
})

router.beforeEach((to, from, next) => {

	if (to.query && to.query.invitation_customer_id) {
		localStorage.setItem("inviterQuery", JSON.stringify(to.query))
	}

	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (store.state.access_token) { // 通过vuex state获取当前的token是否存在
			next();
		} else {
			next({
				path: '/login',
				query: {
					redirectName: to.name
				} // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
		}
	} else {
		next();
	}
})

export default router